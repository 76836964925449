<template>
  <input
    :class="[
      'h-full w-full truncate rounded bg-transparent p-1 text-xs text-gray-600 transition-all focus:bg-gray-500 focus:bg-opacity-5 focus:outline-none focus:ring-1 focus:ring-gray-300',
      groupHover
        ? 'group-hover/row-cells:bg-gray-500 group-hover/row-cells:bg-opacity-5 group-hover/row-cells:ring-1 group-hover/row-cells:ring-gray-300'
        : 'group-hover/cell:bg-gray-500 group-hover/cell:bg-opacity-5 group-hover/cell:ring-1 group-hover/cell:ring-gray-300'
    ]"
    :value="modelValue"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'

const props = defineProps<{
  modelValue: unknown
  debounce?: number
  groupHover?: boolean
}>()
const emit = defineEmits(['update:modelValue'])

const onInput = useDebounceFn((event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}, props.debounce ?? 0)
</script>
