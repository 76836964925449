<template>
  <SheetCell v-if="localColumn" :key="column.id" :="{ column }">
    <div
      ref="containerElement"
      class="flex w-full items-center gap-x-1 p-2 px-1 text-xs"
    >
      <div
        v-if="isDeleting"
        class="flex h-4 w-fit items-center gap-x-2 truncate rounded text-2xs"
      >
        <TwSpinner class="h-3 w-3" />
        Deleting Column...
      </div>
      <SheetInput v-else v-model="columnName" :debounce="500" />

      <div class="-mr-1 flex items-center">
        <TableSort
          v-if="column.sortable"
          :="{ currentSort }"
          :title="columnName"
          :sortBy="column.type"
          @sort="(v) => emit('sort', v)"
        />

        <DropDown v-slot="{ open }" :items="columnOptions" size="sm">
          <Tippy
            is="button"
            content="Options"
            :class="['Btn--plain', open ? 'bg-gray-200' : '']"
          >
            <Icon name="ellipsis" class="h-3.5 w-3.5 -rotate-90" />
          </Tippy>
        </DropDown>
      </div>
    </div>
    <ColumnResizer
      v-if="containerElement"
      :column="column"
      :container="containerElement"
    />
  </SheetCell>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash'
import { useDebounceFn } from '@vueuse/core'
const emit = defineEmits<{ (e: 'sort', value: Sort): void }>()

const { column } = defineProps<{ column: IpSheetColumn; currentSort?: Sort }>()

const { localColumn, setColumn, setColumnAttribute } = useSheetColumn(column)

const containerElement = ref<HTMLElement>()
const columnName = ref(column.name)

const { axios, onError, queryClient } = useApi()
const { mutate: deleteColumn, isLoading: isDeleting } = useMutation({
  mutationFn: async () => {
    const response = await axios.delete<ApiShow<Sheet>>(
      `ip-sheet-columns/${column.id}`
    )
    queryClient.setQueryData(['ip_sheets'], response.data.data)
  },
  onError
})

const { mutate: updateColumn } = useUpdateColumn(column)

const debouncedUpdateColumn = useDebounceFn(updateColumn, 500)

const columnOptions = computed((): NavItem[] => {
  const sticky = Boolean(localColumn.value.attributes?.sticky)

  return [
    {
      title: sticky ? 'Unpin' : 'Pin',
      icon: 'pin',
      handler: () => {
        setColumnAttribute({ sticky: !sticky })
      }
    },
    { title: 'Delete', icon: 'trash', handler: () => deleteColumn() }
  ]
})

onMounted(() => {
  setColumn(column.id, column)
})

watch(localColumn, (v, old) => {
  if (!isEqual(v, column) && !isEqual(v, old)) {
    debouncedUpdateColumn(v)
    columnName.value = v.name
  }
})

watch(columnName, (value) => {
  setColumn(column.id, { name: value })
})
</script>
