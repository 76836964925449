<template>
  <div>
    <Btn
      class="Btn--icon Btn--alt Btn--xs w-8"
      type="button"
      icon="folder-plus"
      tooltip="Add New Family"
      @click="addNewFamilyOpen = true"
    >
    </Btn>
    <LazyNewPatentFamilyPopup
      :open="addNewFamilyOpen"
      @done="addNewFamilyOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
const addNewFamilyOpen = ref(false)
</script>
