<template>
  <div class="h-screen w-[90vw] lg:w-[45rem]">
    <Transition name="page" mode="out-in">
      <div v-if="isLoading" class="h-full w-full">
        <LoadingSpinner />
      </div>
      <div v-else-if="patent" class="flex h-full flex-col gap-y-3 py-4">
        <div class="grid grid-cols-2 text-xs sm:grid-cols-3">
          <PatentAttribute title="Title" :description="patent.Title" />
          <PatentAttribute title="Patent Family">
            <NuxtLink
              :to="`/patents?familyId=${patent.family.id}`"
              class="hover:text-cyan-800"
            >
              {{ patent.family.title }}
            </NuxtLink>
          </PatentAttribute>

          <PatentAttribute title="Status" :description="patent.status" />

          <template v-if="showMore">
            <PatentAttribute v-if="patent.parent" title="Parent Patent">
              <NuxtLink
                :to="{ query: { ...route.query, patentId: patent.parent.id } }"
                class="flex w-fit"
              >
                <PatentCountry :="patent.parent" />
              </NuxtLink>
            </PatentAttribute>

            <PatentAttribute
              title="Application Number"
              :description="patent.applicationNumber"
            />
            <PatentAttribute
              title="Publication Number"
              :description="patent.publicationNumber"
            />
            <PatentAttribute
              title="Application Date"
              :description="formatDate(patent.applicationDate, 'l')"
            />
            <PatentAttribute
              title="Publication Date"
              :description="formatDate(patent.publicationDate, 'l')"
            />
            <PatentAttribute
              title="Applicants"
              :description="
                patent.Assignees.map((assignee) => assignee.PA)
                  .join(', ')
                  .toLowerCase()
              "
            />
            <PatentAttribute
              title="Inventors"
              :description="
                patent.Inventors.map((inventor) => inventor.INV)
                  .join(', ')
                  .toLowerCase()
              "
            />
            <PatentAttribute
              v-if="patent.children?.length"
              title="Child Patents"
            >
              <div class="flex flex-wrap items-center gap-1">
                <NuxtLink
                  v-for="child in patent.children"
                  :key="child.id"
                  :to="{ query: { ...route.query, patentId: child.id } }"
                >
                  <PatentCountry :="child" />
                </NuxtLink>
              </div>
            </PatentAttribute>
          </template>
        </div>

        <Btn
          class="Btn--alt Btn--xs ml-4 w-fit"
          type="button"
          @click="showMore = !showMore"
        >
          {{ showMore ? 'Show Less' : 'Show More..' }}
        </Btn>

        <div class="flex flex-col overflow-y-auto p-4">
          <Tabs
            :tabs="patentInfoTabs"
            :activeTab="patentInfoTab"
            class="sticky top-0"
          >
            <template #transactionHistory>
              <div class="h-100 overflow-y-auto px-[3px] py-4">
                <PatentEvents :events="patent.legalEvents" />
              </div>
            </template>
          </Tabs>
          <div
            v-if="['Abstract', 'Claims', 'Description'].includes(patentInfoTab)"
            class="h-full overflow-x-hidden px-2"
          >
            <div class="my-2 flex flex-col gap-y-2">
              <PatentText :="patentInfoProps" />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
// data
const {
  query: { isLoading, data: patent },
  route
} = useShowPatent('query')

const showMore = ref(false)

const patentInfoTab = computed(
  () => (route.query.patentInfoTab as string) ?? 'Abstract'
)

const patentInfoTabs = computed<NavItem[]>(() => {
  const tabs: [string, string, string?][] = [
    ['Abstract', 'bars-bottom-left'],
    ['Claims', 'scale'],
    ['Description', 'document-text'],
    ['Transaction History', 'clock', 'transactionHistory']
  ]
  return tabs.map(([title, icon, key]) => ({
    title,
    key: key ?? title,
    to: { query: { ...route.query, patentInfoTab: key ?? title } },
    icon
  }))
})

const patentInfoProps = computed(() => {
  const [fullText] = patent.value?.FullText ?? []
  const patentInfoMap: Record<string, unknown> = {
    Abstract: {
      title: patentInfoTab.value,
      text: fullText?.Abstracts,
      node: 'abstract'
    },
    Claims: {
      title: patentInfoTab.value,
      text: fullText?.Claims,
      node: 'claims'
    },
    Description: {
      title: patentInfoTab.value,
      text: fullText?.Descriptions,
      node: 'description'
    }
  }
  return patentInfoMap[patentInfoTab.value]
})
</script>
