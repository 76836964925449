<template>
  <DropDown
    v-slot="{ open }"
    :items="customFields[entity] ?? []"
    placement="bottom-start"
    size="sm"
    class="px-2"
  >
    <Transition name="page" mode="out-in">
      <div
        v-if="isLoading"
        class="flex h-4 w-fit items-center gap-x-2 whitespace-nowrap rounded text-2xs"
      >
        <TwSpinner class="h-3 w-3" />
        Building Column...
      </div>
      <Tippy
        is="button"
        v-else
        content="Add field"
        :class="['Btn--plain my-1.5', open ? 'bg-gray-200' : '']"
        type="button"
      >
        <Icon name="plus" class="h-3 w-3" />
      </Tippy>
    </Transition>
  </DropDown>
</template>

<script setup lang="ts">
const { table } = defineProps<{ entity: Entity; table: IpSheetTable }>()

const { axios, onError, queryClient } = useApi()
const { mutate, isLoading } = useMutation({
  mutationFn: async (item: NavItem) => {
    const response = await axios.post<ApiShow<Sheet>>('ip-sheet-columns', {
      name: item.title,
      index: table.columns.length,
      attributes: { width: 200 },
      type: item.key,
      tableId: table.id
    })
    queryClient.setQueryData(['ip_sheets'], response.data.data)
  },
  onError
})

const addHandler = (item: NavItem): NavItem => ({
  ...item,
  handler: item.key && !item.handler ? () => mutate(item) : undefined
})

const customFields: Partial<Record<Entity, NavItem[]>> = {
  patent_families: [
    { title: 'Tags', icon: 'tag', key: 'families_tags' },
    { title: 'Reminder', icon: 'alarm', key: 'families_reminders' },
    { title: 'Title', icon: 'bars-bottom-left', key: 'families_title' },
    {
      title: 'Patents Count',
      icon: 'clipboard-document-list',
      key: 'families_patentCount'
    },
    {
      title: 'Last Publication Date',
      icon: 'calendar-days',
      key: 'families_lastPublicationDate'
    },
    {
      title: 'Earliest Priority Date',
      icon: 'calendar',
      key: 'families_earliestPriorityDate'
    }
  ].map(addHandler),
  patents: [
    { title: 'Tags', icon: 'tag', key: 'patents_tags' },
    { title: 'Reminder', icon: 'alarm', key: 'patents_reminders' },
    { title: 'Title', icon: 'bars-bottom-left', key: 'patents_title' },
    {
      title: 'Publication Number',
      icon: 'hashtag',
      key: 'patents_publicationNumber'
    },
    { title: 'Country', icon: 'globe-alt', key: 'patents_country' },
    { title: 'Status', icon: 'info-circle', key: 'patents_status' },
    {
      title: 'Application Number',
      icon: 'qr-code',
      key: 'patents_applicationNumber'
    },
    {
      title: 'Publication Date',
      icon: 'calendar-days',
      key: 'patents_publicationDate'
    }
  ].map(addHandler)
}
</script>
