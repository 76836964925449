export const useTags = () => {
  const { axios, onError } = useApi()

  return useQuery({
    queryFn: async () => {
      const { data } = await axios.get<ApiList<Tag>>('tags')
      return new Map(data.data.map((tag) => [tag.id, tag]))
    },
    queryKey: ['tags'],
    onError
  })
}
