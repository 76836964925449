<template>
  <div ref="tableContainer" class="flex flex-col rounded-lg border shadow-sm">
    <Transition name="page" mode="out-in">
      <SheetSkeleton v-if="loading" :="{ skeleton }" />
      <div
        v-else-if="table.rows.length"
        class="relative flex flex-col overflow-x-auto rounded-lg"
      >
        <!-- Header -->
        <div
          class="group/row sticky top-0 flex h-8 min-h-8 w-full min-w-fit items-center rounded-t-lg bg-gray-50 text-gray-600 shadow-sm hover:bg-gray-100"
          :style="{ zIndex: 20 - level }"
        >
          <slot name="headerRow"></slot>
        </div>
        <!-- Body -->
        <slot
          v-for="(row, i) in table.rows"
          :key="row.source.id"
          :="{ row, i, columns, level, entity: table?.table?.entity }"
        ></slot>
      </div>
      <Empty v-else :message="emptyMessage" class="text-cyan-900">
        <slot name="empty"></slot>
      </Empty>
    </Transition>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { HTMLAttributes } from 'nuxt/dist/app/compat/capi'

const { table } = defineProps<{
  table: SheetTable
  loading?: boolean
  skeleton?: Skeleton
  bodyAttrs?: HTMLAttributes
  emptyMessage?: string
}>()

const { level: parentLevel, ...parentContext } = useSheetContext()
const level = computed(() => parentLevel.value + 2)
const columns = reactive<Record<string, ColumnUpdate>>({})
const tableContainer = ref<HTMLElement>()
parentContext.tableContainer = tableContainer

if (table) {
  parentContext.table = ref(table)
}

provide('context', { level, ...parentContext })
</script>
