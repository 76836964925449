<template>
  <div class="Page w-full">
    <IpSheet
      :tables="sheet?.tables ?? []"
      :loading="isLoading"
      class="max-h-[calc(100vh-8rem)]"
    />
    <SlideModal
      :open="!!route.query.patentId"
      slideFrom="right"
      @close="navigateTo({ query: { ...route.query, patentId: undefined } })"
    >
      <PatentDetails />
    </SlideModal>
  </div>
</template>

<script setup lang="ts">
useDragDropProvider()
const route = useRoute()
const { isLoading, data: sheet } = useIpSheet()
</script>
