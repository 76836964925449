<template>
  <SheetCell :column="cell.column" class="justify-between p-2 text-xs">
    <span class="truncate">{{ cell.value }}</span>
    <div class="flex items-center gap-x-1">
      <LazyMergedPatents :patent="source" />
      <DropDown
        v-slot="{ open }"
        :items="patentOptions"
        size="sm"
        placement="bottom-start"
      >
        <Tippy
          is="button"
          content="Options"
          :class="['Btn--plain', open ? 'bg-gray-200' : '']"
        >
          <Icon name="ellipsis" class="h-3.5 w-3.5 -rotate-90" />
        </Tippy>
      </DropDown>
      <Tippy content="Details" class="flex">
        <NuxtLink
          class="Btn--plain"
          :to="{ query: { ...route.query, patentId: source.id } }"
        >
          <Icon name="chevron" class="h-3.5 w-3.5 -rotate-90" />
        </NuxtLink>
      </Tippy>
    </div>
    <TemplateSwitcher v-if="action" :template="action">
      <template #archive>
        <LazyArchivePatentModal :="{ patent }" @done="patent = undefined" />
      </template>
      <template #changeFamily>
        <LazyChangePatentFamilyModal
          :="{ destination: destinationFamily, patent }"
          @done="patent = undefined"
        />
      </template>
      <template #mergePatents>
        <LazyMergePatentsModal
          :="{ destination: destinationPatent, patent }"
          @done="patent = undefined"
        />
      </template>
    </TemplateSwitcher>
  </SheetCell>
</template>

<script setup lang="ts">
const { source } = defineProps<{ source: Patent; cell: CellType }>()

const route = useRoute()
const patent = ref<Patent>()
const action = ref<'archive' | 'changeFamily' | 'mergePatents'>()
const destinationPatent = ref<Patent>()
const destinationFamily = ref<PatentFamily>()

const { table } = useSheetContext<Patent>()
const { data: patentFamilies, isLoading: patentFamiliesLoading } =
  usePatentFamilies()

const patentOptions = computed((): NavItem[] => [
  {
    title: 'Change Family',
    icon: 'arrows-up-down',
    loading: patentFamiliesLoading.value,
    children: patentFamilies.value?.data.map((family) => ({
      title: family.title,
      disabled: source.family?.id === family.id ? 'Current Family' : undefined,
      handler: () => {
        action.value = 'changeFamily'
        patent.value = source
        destinationFamily.value = family
      }
    }))
  },
  {
    title: 'Merge',
    icon: 'merge',
    children: table.value?.rows?.map(
      ({ source: destination }): NavItem => ({
        title: destination.publicationNumber,
        image: getFlag(destination.countryCode),
        disabled: destination.id === source.id ? 'Current Patent' : undefined,
        handler: () => {
          action.value = 'mergePatents'
          patent.value = source
          destinationPatent.value = destination
        }
      })
    )
  },
  {
    title: 'Archive',
    icon: 'archive-x',
    handler: () => {
      action.value = 'archive'
      patent.value = source
    }
  }
])

const { axios, refetchQuery, onError } = useApi()
const archivePatent = async () => {
  await axios.delete(`patents/${source.id}`).catch(onError)
  refetchQuery(['ip_sheets'])
}
</script>
