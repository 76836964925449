<template>
  <div
    :class="[
      'group/cell flex h-full items-center border-r text-left transition-all last:border-none',
      localColumn?.attributes.sticky
        ? 'sticky left-0 top-0 z-[1] bg-gray-50 group-hover/row:bg-gray-100'
        : ''
    ]"
    :style="localColumn && { width: `${localColumn.attributes.width}px` }"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const { column } = defineProps<{ column?: IpSheetColumn }>()

const { columns } = useSheetContext()

const localColumn = computed(() => column && columns[column.id])
</script>
