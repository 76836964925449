<template>
  <div
    ref="dropElement"
    :="{ draggable }"
    class="group/row relative flex flex-col rounded-lg hover:bg-gray-100"
    @dragstart="onDragStart"
    @dragover="onDragEnter"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <!-- Row Columns -->
    <div
      :class="[
        'group/row-cells pointer-events-none relative flex h-8 min-w-fit border-b transition-all group-last/row:border-b-0',
        childIsOpen
          ? 'sticky top-8 bg-gray-50 group-hover/row:bg-gray-100'
          : '',
        isDraggedOver ? '' : 'group-hover/row:pointer-events-auto'
      ]"
      :style="{ zIndex: childIsOpen ? 19 - level : undefined }"
    >
      <SheetCell
        v-if="openChild"
        class="justify-center p-1"
        @mouseenter="setRenderChild(true)"
        @mouseleave="setRenderChild(false)"
      >
        <Tippy
          is="button"
          :content="openChild"
          class="Btn--plain"
          @focus="setRenderChild(true)"
          @click="emit('open', !childIsOpen)"
        >
          <Icon
            name="chevron"
            :class="[
              'hidden h-4 w-4 text-gray-700 sm:flex',
              childIsOpen ? 'rotate-0' : '-rotate-90'
            ]"
          />
        </Tippy>
      </SheetCell>
      <slot></slot>
      <div
        v-if="draggable"
        class="absolute right-2 top-0 flex h-full items-center"
      >
        <Icon
          name="draggable"
          class="h-4 w-4 text-gray-400 opacity-0 group-hover/row-cells:opacity-100"
        />
      </div>
    </div>
    <!-- Drop Area -->
    <div
      :class="[
        'droparea h-28 transition-[max-height] duration-300',
        isDraggedOver && !childIsOpen
          ? 'max-h-28 overflow-y-auto border-b p-2'
          : 'max-h-0 overflow-hidden'
      ]"
    >
      <div
        class="flex h-full w-full items-center justify-center rounded-lg bg-gray-100 text-gray-500 ring-1 ring-gray-200"
      >
        <slot name="dropArea"></slot>
      </div>
    </div>
    <!-- Drop Overlay -->
    <Transition name="page">
      <div
        v-if="isDraggedOver && childIsOpen"
        class="absolute left-0 top-8 z-30 flex h-[calc(100%-2rem)] w-full items-center justify-center bg-cyan-700 bg-opacity-5 text-cyan-800 backdrop-blur-sm"
      >
        <slot name="dropArea"></slot>
      </div>
    </Transition>
    <!-- Child -->
    <div
      :class="[
        'transition-[max-height] duration-300',
        childIsOpen && !isResizing
          ? 'hide-scrollbar max-h-screen overflow-y-auto'
          : 'max-h-0 overflow-hidden'
      ]"
    >
      <slot
        v-if="openChild && (renderChild || childIsOpen)"
        name="child"
        :="{ openChild, renderChild, childIsOpen }"
      ></slot>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends { id: string }">
import { useDragDrop } from '~/composables/dragDrop'

// defines
const { draggable, droppable, childIsOpen, openChild, entity, item } =
  defineProps<{
    childIsOpen?: boolean
    draggable?: boolean
    droppable?: boolean
    openChild?: string
    item?: T
    entity?: Entity
  }>()

const emit = defineEmits<{ (e: 'open', value: boolean): void }>()

// data
const renderChild = ref(childIsOpen)
const { level, isResizingColumn, table } = useSheetContext()

const isResizing = computed(() => {
  for (const column of table.value?.table.columns ?? []) {
    if (column.id === isResizingColumn.value) return true
  }
  return false
})

const {
  isDraggedOver,
  dropElement,
  onDragStart,
  onDragEnter,
  onDragLeave,
  onDrop
} = useDragDrop({
  item: draggable ? { entity, item } : undefined,
  dropItem: droppable ? { entity, item } : undefined
})

const setRenderChild = (value: boolean) => {
  requestAnimationFrame(() => {
    renderChild.value = value
  })
}
</script>
