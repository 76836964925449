<template>
  <SheetCell :column="cell.column" class="px-3 text-xs">
    <AppPopOver class="max-w-full" placement="bottom-start">
      <template #button>
        <button class="max-w-full truncate capitalize" type="button">
          {{ applicantsText }}
        </button>
      </template>
      <div
        class="flex max-w-72 flex-col justify-center gap-y-2 rounded-xl bg-white p-3 py-3 text-left capitalize"
      >
        {{ applicantsText }}
      </div>
    </AppPopOver>
  </SheetCell>
</template>

<script setup lang="ts">
const { cell } = defineProps<{ cell: CellType<PatbaseAssignee[]> }>()
const applicantsText = computed(
  () =>
    cell.value
      ?.map((applicant) => applicant.PA)
      .join(', ')
      .toLowerCase() || 'No Applicants'
)
</script>
