<template>
  <div
    :class="[
      'pointer-events-none absolute',
      isResizing ? 'box-content border-r border-cyan-700' : ''
    ]"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
      top: 0,
      left: containerRect.left
    }"
  >
    <div
      :class="[
        'pointer-events-auto absolute right-0 h-8  w-1 translate-x-0.5 cursor-col-resize  rounded bg-cyan-700 transition-all',
        isResizing ? '' : 'opacity-0 hover:opacity-100'
      ]"
      @mousedown="onMouseDown"
    ></div>
  </div>
</template>

<script setup lang="ts">
const { container, column } = defineProps<{
  container: HTMLElement
  column: IpSheetColumn
}>()

const {
  isResizingColumn,
  tableContainer,
  setIsResizingColumn,
  setColumnAttribute
} = useSheetColumn(column)

const width = ref(container.getBoundingClientRect().width)
const height = computed(
  () => Number(tableContainer.value?.getBoundingClientRect().height) - 6
)
const isResizing = computed(() => isResizingColumn.value === column.id)
const containerRect = computed(() => container.getBoundingClientRect())

const onMouseDown = (event: MouseEvent) => {
  preventDefault(event)
  setIsResizingColumn(column.id)
}

const onMouseUp = () => {
  if (isResizing.value !== true) return
  setColumnAttribute({ width: width.value })
  setIsResizingColumn(undefined)
}

const onMouseMove = (event: MouseEvent) => {
  if (isResizing.value !== true) return
  preventDefault(event)
  const rect = container.getBoundingClientRect()
  const diff = event.clientX - (rect.x + rect.width)
  const newWidth = rect.width + diff
  width.value = Math.min(Math.max(newWidth, 100), 1000)
}

onMounted(() => {
  document.addEventListener('mouseup', onMouseUp)
  document.addEventListener('mousemove', onMouseMove)
})

onBeforeUnmount(() => {
  document.removeEventListener('mouseup', onMouseUp)
  document.removeEventListener('mousemove', onMouseMove)
})
</script>
