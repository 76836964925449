<template>
  <SheetCell :column="cell.column" class="px-1 py-1">
    <SheetInput v-model="title" :debounce="500" />
    <DropDown
      v-slot="{ open }"
      :items="options"
      size="sm"
      placement="bottom-end"
    >
      <Tippy
        is="button"
        content="Options"
        :class="[
          'Btn--plain',
          open ? 'bg-gray-200' : 'hidden group-hover/cell:flex'
        ]"
      >
        <Icon name="ellipsis" class="h-3.5 w-3.5 -rotate-90" />
      </Tippy>
    </DropDown>
    <TemplateSwitcher v-if="action" :template="action">
      <template #archive>
        <div class="contents">
          <ConfirmAction
            action="Archive"
            :actionFn="archivePatentFamily"
            :item="patentFamily"
            :name="(item) => item.title.toLowerCase()"
            @done="patentFamily = undefined"
          />
        </div>
      </template>
    </TemplateSwitcher>
  </SheetCell>
</template>

<script setup lang="ts">
const { cell, source } = defineProps<{ cell: CellType; source: PatentFamily }>()

const title = ref(cell.value)
const { axios, onError, refetchQuery } = useApi()
const action = ref<'archive'>()
const patentFamily = ref<PatentFamily>()

const options = computed((): NavItem[] => [
  {
    title: 'Archive',
    icon: 'archive-x',
    handler: () => {
      action.value = 'archive'
      patentFamily.value = source
    }
  }
])

const archivePatentFamily = async (patentFamily: PatentFamily) => {
  if (!patentFamily) return
  await axios.delete(`patent-families/${patentFamily.id}`).catch(onError)
  return refetchQuery(['ip_sheets'])
}

watch(title, async (value) => {
  await axios
    .put(`/patent-families/${source.id}`, { title: value })
    .catch(onError)
  refetchQuery(['ip_sheets'])
})
</script>
