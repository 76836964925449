export const useIpSheet = () => {
  const { axios, onError } = useApi()
  return useQuery({
    queryFn: async () => {
      const response = await axios.get<ApiShow<Sheet>>('ip-sheets')
      return response.data.data
    },
    onError,
    queryKey: ['ip_sheets']
  })
}

export const useUpdateColumn = (column: IpSheetColumn) => {
  const { axios, onError, queryClient } = useApi()
  return useMutation({
    mutationFn: async (updates: Partial<ColumnUpdate>) => {
      const response = await axios.put<ApiShow<Sheet>>(
        `ip-sheet-columns/${column.id}`,
        updates
      )
      queryClient.setQueryData(['ip_sheets'], response.data.data)
    },
    onError
  })
}
