<template>
  <div class="flex flex-col gap-y-2">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-x-3">
        <span class="text-[13px] text-gray-600">
          {{ table.table.name }}
        </span>
        <TemplateSwitcher :template="table.table.entity">
          <template #patent_families>
            <PatentFamiliesActions />
          </template>
        </TemplateSwitcher>
      </div>
      <div class="relative flex w-fit min-w-72 flex-col items-center gap-x-3">
        <span
          class="pointer-events-none h-0 w-fit max-w-120 truncate px-2 text-xs opacity-0"
        >
          {{ searchPlaceHolder }}
        </span>
        <AppInput
          id="searchTerm"
          v-model="search"
          :placeholder="searchPlaceHolder"
          class="w-full"
          :debounce="500"
          size="sm"
        />
      </div>
    </div>
    <Sheet :="{ ...$attrs, table, loading: isLoading }">
      <template #headerRow>
        <SheetCell v-if="table.table.isParent" class="min-w-[33px]" />
        <ColumnHeader
          v-for="column in table.table.columns"
          :key="column.id"
          :="{ column }"
          :currentSort="sort"
          @sort="(v) => (sort = v)"
        />
        <AddColumn :entity="table.table.entity" :table="table.table" />
      </template>
      <template #default="{ row, i, entity }">
        <SheetRow
          v-if="entity"
          :entity="entity"
          :item="row.source"
          :openChild="table.table.isParent ? 'Open' : undefined"
          class="text-gray-600"
          :childIsOpen="route.query[row.source.id] === 'true'"
          :draggable="tableProps[table.table.entity]?.['draggable']"
          droppable
          @open="(open) => updateOpen(row.source.id, open)"
        >
          <template #default>
            <CellSelector
              v-for="cell in row.cells"
              :key="`${row.source.id}/${cell.column.id}`"
              :="{ cell, source: row.source }"
            />

            <div></div>
          </template>
          <template v-if="table.table.isParent" #child>
            <div
              class="flex flex-col gap-y-2 border-b bg-gray-50 p-2 pl-[32px]"
            >
              <IpSheet :tables="row.children" />
            </div>
          </template>
          <template #dropArea>
            <DropSelector
              :destination="row.source"
              :entity="table.table.entity"
            />
          </template>
        </SheetRow>
      </template>
      <template #empty>
        <LazyPatentSearchModal />
      </template>
    </Sheet>
  </div>
</template>

<script setup lang="ts">
const { table } = defineProps<{ table: SheetTable }>()
defineOptions({ inheritAttrs: false })

const tableProps: Partial<Record<Entity, Record<string, any>>> = {
  patents: { draggable: true }
}

const route = useRoute()
const { columns } = useSheetContext()
const search = ref(table.table.query?.search)
const sort = ref<Sort>(table.table.query?.sort)

const searchableColumns = computed(() => {
  const columns = new Map<string, string>()
  for (const column of table.table.columns) {
    if (column.searchable) columns.set(column.id, column.name)
  }
  return columns
})

const searchPlaceHolder = computed(() => {
  const names: string[] = []
  for (const [columnId, defaultName] of searchableColumns.value) {
    const columnName = columns[columnId]?.name ?? defaultName
    names.push(columnName)
  }
  return `Search by ${names.join(' or ')}`
})

const { axios, queryClient } = useApi()

const { mutate: updateTable, isLoading } = useMutation({
  mutationFn: async (updates: Partial<TableUpdate>) => {
    const response = await axios.put(
      `ip-sheet-tables/${table.table.id}`,
      updates
    )
    queryClient.setQueryData(['ip_sheets'], response.data.data)
  }
})

const updateOpen = (rowId: string, open: boolean) => {
  navigateTo({ query: { ...route.query, [rowId]: open ? 'true' : undefined } })
}

watch(search, (value) => {
  updateTable({ query: { search: value } })
})

watch(sort, (value) => {
  updateTable({ query: { sort: value } })
})
</script>
