<template>
  <SheetCell :column="cell.column" class="gap-x-1 p-2">
    <AppPopOver class="pointer-events-none w-full">
      <template #button="{ open }">
        <div
          class="flex min-h-8 min-w-full items-center gap-x-1 overflow-hidden"
        >
          <button
            v-for="tag in tags"
            :key="tag.id"
            type="button"
            :class="[
              'pointer-events-auto flex items-center justify-center rounded-full focus:outline-none',
              tags.length > 2 ? 'h-1.5 w-full' : 'h-5 w-fit cursor-pointer px-3'
            ]"
            :style="{ backgroundColor: tag.color }"
          >
            <span v-if="tags.length < 3" class="truncate text-2xs text-white">
              {{ tag.name }}
            </span>
          </button>
          <Btn
            v-if="tags.length < 1"
            :class="[
              'Btn--xs Btn--icon pointer-events-auto ml-2 !h-5',
              open ? 'ring-2 ring-cyan-900 ring-opacity-25' : ''
            ]"
          >
            <Icon name="tag" class="h-3 w-3" />
            <span class="text-2xs">Add tag</span>
          </Btn>
        </div>
      </template>
      <template #default>
        <div
          class="flex w-72 flex-col justify-center gap-y-2 rounded-xl bg-white p-3 py-3 text-left capitalize"
        >
          <LazySelectTags v-model="tags" />
        </div>
      </template>
    </AppPopOver>
  </SheetCell>
</template>

<script setup lang="ts">
const { cell, source } = defineProps<{
  cell: CellType<Tag[]>
  source: Patent
}>()

const tags = reactive<Tag[]>([...cell.value])
const { data: allTags } = useTags()
const { axios, onError, refetchQuery } = useApi()

watch(allTags, (value) => {
  if (!value) return
  for (const i in tags) {
    const tag = tags[i]
    if (value.has(tag.id)) continue
    tags.splice(Number(i), 1)
  }
})

watch(tags, async (newTags) => {
  const tagIds = newTags.map((tag) => tag.id)
  await axios
    .post('patent-family-tags', {
      entity: 'ip_sheet_columns',
      refId: cell.column.id,
      patentFamilyId: source.id,
      tagIds
    })
    .catch(onError)
  refetchQuery(['ip_sheets'])
})
</script>
