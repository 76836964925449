type DragDropContext<Drag, Drop> = {
  dragItem: globalThis.Ref<DragDropItem<Drag> | undefined>
  dropItem: globalThis.Ref<DragDropItem<Drop> | undefined>
  setDragItem: (value: DragDropItem<Drag> | undefined) => void
  setDropItem: (value: DragDropItem<Drop> | undefined) => void
}

export const useDragDropProvider = <Drag = unknown, Drop = unknown>() => {
  const dragItem = ref<DragDropItem<Drag>>()
  const dropItem = ref<DragDropItem<Drop>>()

  provide<DragDropContext<Drag, Drop>>('dragDropContext', {
    dragItem,
    dropItem,
    setDragItem: (value: DragDropItem<Drag> | undefined) =>
      (dragItem.value = value),
    setDropItem: (value: DragDropItem<Drop> | undefined) =>
      (dropItem.value = value)
  })
}

export const useDragDrop = <Drag = unknown, Drop = unknown>(options: {
  item?: DragDropItem<Drag>
  dropItem?: DragDropItem<Drop>
  dropFn?: (dragItem: DragDropItem<Drag>, dropItem: DragDropItem<Drop>) => void
}) => {
  const { dropItem: currentDropItem, dropFn, item } = options

  const dropElement = ref<HTMLElement>()

  const context = inject<DragDropContext<Drag, Drop>>('dragDropContext')

  if (!context) throw new Error('no drag context set')

  const { dragItem, dropItem, setDragItem, setDropItem } = context

  const isDragging = ref(false)
  const isDraggedOver = ref(false)

  const onDragEnter = (event: DragEvent) => {
    if (!currentDropItem || isDragging.value || !dropElement.value) return
    const target = event.relatedTarget as HTMLElement
    if (dropElement.value.contains(target)) return
    preventDefault(event)
    isDraggedOver.value = true
  }

  const onDragLeave = (event: DragEvent) => {
    if (!dropElement.value || !dragItem.value) return
    const target = event.relatedTarget as HTMLElement
    if (dropElement.value.contains(target)) return
    isDraggedOver.value = false
  }

  const onDragStart = () => {
    isDragging.value = true
    if (!dragItem.value) setDragItem(item)
  }

  const onDragEnd = () => {
    isDraggedOver.value = false
    setDragItem(undefined)
    setDropItem(undefined)
  }

  const onDrop = (event: MouseEvent) => {
    preventDefault(event)
    isDragging.value = false
    setDropItem(currentDropItem)
  }

  watch(dropItem, (value) => {
    if (!value && !dragItem.value) return (isDraggedOver.value = false)
    if (!value || !dragItem.value || !dropFn) return

    dropFn(dragItem.value, value)
  })

  return {
    dragItem,
    dropItem,
    dropElement,
    isDragging,
    isDraggedOver,
    onDragStart,
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDrop,
    setDragItem,
    setDropItem
  }
}
