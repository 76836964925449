export const useSheetContext = <
  RowSource extends Record<string, unknown>
>() => {
  const level = ref(-2)
  const columns = reactive<Record<string, ColumnUpdate>>({})
  const table = ref<SheetTable<RowSource>>()
  const tableContainer = ref<HTMLElement>()
  const isResizingColumn = ref<string>()

  const context = inject('context', {
    level,
    columns,
    table,
    isResizingColumn,
    tableContainer,
    setIsResizingColumn: (id: string | undefined) => {
      isResizingColumn.value = id
    },
    setColumn: (id: string, column: Partial<ColumnUpdate>) => {
      columns[id] = { ...columns[id], ...column }
    }
  })

  return context
}

export const useSheetColumn = (column: IpSheetColumn) => {
  const {
    columns,
    setColumn,
    isResizingColumn,
    tableContainer,
    setIsResizingColumn
  } = useSheetContext()

  const setColumnAttribute = (
    attributes: Partial<IpSheetColumn['attributes']>
  ) => {
    setColumn(column.id, {
      attributes: { ...localColumn.value.attributes, ...attributes }
    })
  }

  const localColumn = computed(() => columns[column.id])

  return {
    columns,
    setColumn,
    isResizingColumn,
    tableContainer,
    setIsResizingColumn,
    localColumn,
    setColumnAttribute
  }
}
